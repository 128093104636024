import { ChangeEvent, useEffect, useRef, useState } from "react";
import { InputField } from "../../Components/InputField";
import { CustomButton } from "../../Components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { Prices, Product, Specification } from "../../Components/Product.types";
import Switch from "react-switch";
import {
  casNoReg,
  catalogNoReg,
  productNameReg,
  shortDescReg,
} from "../../regex";
import { ErrorSpan } from "../../Components/ErrorSpan";
import {
  axiosInstance,
  useAxiosInterceptor,
} from "../../Interceptors/authInterceptor";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";

export const ProductForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  useEffect(() => {
    if (!user.user?.accessibility[0]) {
      navigate("/admin/unauthorized");
    }
  }, [user, navigate]);
  useAxiosInterceptor();

  const imageRef = useRef<HTMLInputElement>(null);
  const docRef = useRef<HTMLInputElement>(null);
  const { id } = useParams();
  const [isDocumentDeleted, setIsDocumentDeleted] = useState<boolean>(false);

  const [product, setProduct] = useState<Product>({
    id: "",
    name: "",
    catalogNo: "",
    casNo: "",
    shortDescription: "",
    category: null,
    keyPoints: [],
    specifications: [],
    image: null,
    document: null,
    prices: [],
    order: "",
  });
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [keyPointsError, setKeyPointsError] = useState<Array<string>>([]);
  const [specificationError, setSpecificationError] = useState<Array<any>>([]);
  const [pricesError, setPricesError] = useState<Array<any>>([]);
  const [disabled, setDisabled] = useState<number>(1);
  const [available, setAvailable] = useState<number>(1)
  const [errors, setErrors] = useState<any>({
    name: "",
    catalogNo: "",
    casNo: "",
    shortDescription: "",
    category: "",
    image: "",
    document: "",
  });

  useEffect(() => {
    const getProduct = async () => {
      try {
        const response = await axiosInstance.get(`api/admin/product/${id}`, {
          withCredentials: true,
        });
        if (response != null) {
          setProduct(response.data.result);
          setImagePreview(response.data.result.image.toString());
          setAvailable(response.data.result.availability);
          setDisabled(response.data.result.status)
          setSpecificationError((prev) => {
            let newSpecErr = prev;
            response.data.result.specifications.map((spec: any) => {
              newSpecErr.push({
                specificationType: "",
                specificationDetail: "",
              });
              return "";
            });
            return newSpecErr;
          });
        }
      } catch (error) {
        console.error("Error fetching part:", error);
      }
    };

    if (id) {
      getProduct();
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const addKeyPoint = (type: string) => {
    if (type === "keyPoint") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        keyPoints: [...prevProduct.keyPoints, ""],
      }));
      setKeyPointsError((prevErrors) => [...prevErrors, ""]);
    } else if (type === "specification") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        specifications: [...prevProduct.specifications, { key: "", value: "" }],
      }));
      setSpecificationError((prevErrors: any) => {
        return [...prevErrors, { key: "", value: "" }];
      });
    } else if (type === "price") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        prices: [...prevProduct.prices, { quantity: "", price: 0 }],
      }));
      setPricesError((prevErrors: any) => {
        return [...prevErrors, { quantity: "", price: "" }];
      });
    }
  };

  const handleDisableProduct = (isDisable:number) => {
    if(isDisable === 0){

      setDisabled((prev) => {
        return prev === 0 ? 1: 0;
      });
    } else {
      setAvailable((prev) => {
        return prev === 0 ? 1: 0;
      });
    }
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        productDocument: file,
      }));
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        productImg: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyPointsChange = (index: number, value: string) => {
    setProduct((prevProduct) => {
      const newKeyPoints = [...prevProduct.keyPoints];
      newKeyPoints[index] = value;
      return {
        ...prevProduct,
        keyPoints: newKeyPoints,
      };
    });
  };

  const handleKeyBlur = (index: number, value: string) => {
    setKeyPointsError((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] =
        value.trim() === "" ? "Key Point can not be empty" : "";
      return updatedErrors;
    });
  };

  const handleSpecificationChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    fieldName: string
  ) => {
    const { value } = event.target;
    setProduct((prevProduct) => {
      const updatedSpecifications = [...prevProduct.specifications];
      updatedSpecifications[index] = {
        ...updatedSpecifications[index],
        [fieldName]: value,
      };
      return { ...prevProduct, specifications: updatedSpecifications };
    });
  };

  const handlePriceChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    fieldName: string
  ) => {
    const { value } = event.target;
    setProduct((prevProduct) => {
      const updatePrice = [...prevProduct.prices];
      updatePrice[index] = {
        ...updatePrice[index],
        [fieldName]: value,
      };
      return { ...prevProduct, prices: updatePrice };
    });
  };

  const handleSpecificationBlur = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    fieldName: string
  ) => {
    const { value } = event.target;
    setSpecificationError((prevErrors: any) =>
      prevErrors.map((specErr: any, i: number) => {
        if (i === index) {
          return {
            ...specErr,
            [fieldName]: validateSpecificationField(fieldName, value),
          };
        }
        return specErr;
      })
    );
  };

  const handlePriceBlur = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    fieldName: string
  ) => {
    const { value } = event.target;
    setPricesError((prevErrors: any) =>
      prevErrors.map((priceErr: any, i: number) => {
        if (i === index) {
          return {
            ...priceErr,
            [fieldName]: validatePrices(fieldName, value),
          };
        }
        return priceErr;
      })
    );
  };

  const validateSpecificationField = (field: string, value: string) => {
    switch (field) {
      case "specificationType":
        return value.trim() === "" ? "This field is required" : "";
      case "specificationDetail":
        return value.trim() === "" ? "This field is required" : "";
      default:
        return "";
    }
  };

  const validatePrices = (field: string, value: string) => {
    switch (field) {
      case "quantity":
        return value.trim() === "" ? "This field is required" : "";
      case "price":
        return value === "0" ? "This field is required" : "";
      default:
        return "";
    }
  };

  const removeKeyPoint = (type: string, index: number) => {
    if (type === "keyPoint") {
      setProduct((prevProduct) => {
        const newKeyPoints = [...prevProduct.keyPoints];
        newKeyPoints.splice(index, 1);
        return {
          ...prevProduct,
          keyPoints: newKeyPoints,
        };
      });
      setKeyPointsError((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors.splice(index, 1);
        return newErrors;
      });
    } else if (type === "specification") {
      setProduct((prevProduct) => {
        const newSpecification = [...prevProduct.specifications];
        newSpecification.splice(index, 1);
        return {
          ...prevProduct,
          specifications: newSpecification,
        };
      });
      setSpecificationError((prevErrors: any[]) => {
        return prevErrors.filter((_, i) => i !== index);
      });
    } else if (type === "price") {
      setProduct((prevProduct) => {
        const newPrice = [...prevProduct.prices];
        newPrice.splice(index, 1);
        return {
          ...prevProduct,
          prices: newPrice,
        };
      });
      setPricesError((prevErrors: any[]) => {
        return prevErrors.filter((_, i) => i !== index);
      });
    }
  };
  const requiredErr = "This Field is Mandatory";
  const validateForm = (formData: FormData) => {
    const file = formData.get("productDocument") as File;
    const image = formData.get("productImg") as File;
    const tempErr = {
      name:
        formData.get("name") === ""
          ? requiredErr
          : !productNameReg.test(formData.get("name")?.toString().trim() || "")
          ? "Enter Valid Name"
          : "",

      catalogNo:
        formData.get("catalogNo") === ""
          ? requiredErr
          : !catalogNoReg.test(
              formData.get("catalogNo")?.toString().trim() || ""
            )
          ? "Enter Valid Catalog Number"
          : "",

      casNo:
        formData.get("casNo") !== "" &&
        !casNoReg.test(formData.get("casNo")?.toString().trim() || "")
          ? "Enter Valid Cas Number"
          : "",
      category: formData.get("category") === "0" ? requiredErr : "",

      shortDescription: !shortDescReg.test(
        formData.get("shortDescription")?.toString().trim() || ""
      )
        ? "Short Description Not Valid"
        : "",

      productImg:
        image && image.name === ""
          ? requiredErr
          : image &&
            !["image/webp", "image/jpg", "image/jpeg", "image/png"].includes(
              image.type
            )
          ? "Image should be in WEBP/JPG/JPEG/PNG Format"
          : "",

      productDocument:
        file !== null && file.name !== "" && file.type !== "application/pdf"
          ? "Document should be in .pdf format only"
          : "",

      order:  
      formData.get("order") === "" ? "" :Number(formData.get('order')) < 1 || Number(formData.get('order')) > 9999
        ? "Order should be between 1 and 9999"
        : "",
    };
    setErrors(tempErr);
    let hasErrors = Object.values(tempErr).some((error) => error !== "");
    return hasErrors;
  };

  const handleInputBlur = (event: any) => {
    const { name, value } = event.target;

    let errorMessage = "";
    switch (name) {
      case "name":
        errorMessage =
          value.trim() === ""
            ? requiredErr
            : !productNameReg.test(value.trim())
            ? "Enter Valid Name"
            : "";
        break;
      case "catalogNo":
        errorMessage =
          value.trim() === ""
            ? requiredErr
            : !catalogNoReg.test(value.trim())
            ? "Enter Valid Catalog Number"
            : "";
        break;
      case "casNo":
        errorMessage =
          value.trim() !== "" && !casNoReg.test(value.trim())
            ? "Enter Valid Cas Number"
            : "";
        break;
      case "category":
        errorMessage = value.trim() === 0 ? requiredErr : "";
        break;
      case "shortDescription":
        errorMessage = !shortDescReg.test(value.trim())
          ? "Short Description Not Valid"
          : "";
        break;
      case "order":
        errorMessage = value === "" ? "" : Number(value) < 1 || Number(value) > 9999
        ? "Order should be between 1 and 9999"
        : ""
        break;
      case "productImg":
        const image = event.target.files;
        errorMessage = !image[0]
          ? requiredErr
          : !["image/webp", "image/jpg", "image/jpeg", "image/png"].includes(
              image[0].type
            )
          ? "Image should be in WEBP/JPG/JPEG/PNG Format"
          : "";
        break;
      case "productDocument":
        const doc = event.target.files;
        errorMessage =
          doc[0] && doc[0].type !== "application/pdf"
            ? "Document should be in .pdf format only"
            : "";
        break;
      default:
        break;
    }
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const validateKeypoints = (keypoints: Array<string>) => {
    let hasError = false;
    keypoints.map((keyPoint, index) => {
      if (keyPoint === "") {
        hasError = true;
        setKeyPointsError((prev) => {
          const updated = [...prev];
          updated[index] = requiredErr;
          return updated;
        });
      }
      return "";
    });
    return hasError;
  };

  const validateSpecification = (specs: Specification[]) => {
    let hasError = false;
    specs.map((spec, index) => {
      if (spec.key.trim() === "") {
        hasError = true;
        setSpecificationError((prevSpecs) => {
          let newSpec = prevSpecs;
          newSpec[index].specificationType = requiredErr;
          return newSpec;
        });
      }
      if (spec.value.trim() === "") {
        hasError = true;
        setSpecificationError((prevSpecs) => {
          let newSpec = prevSpecs;
          newSpec[index].specificationDetail = requiredErr;
          return newSpec;
        });
      }
      return "";
    });
    return hasError;
  };

  const validateAllPrices = (prices: Prices[]) => {
    let hasError = false;
    prices.map((price, index) => {
      if (price.quantity.trim() === "") {
        hasError = true;
        setPricesError((prevSpecs) => {
          let newPrice = prevSpecs;
          newPrice[index].quantity = requiredErr;
          return newPrice;
        });
      }
      if (price.price === 0) {
        hasError = true;
        setPricesError((prevSpecs) => {
          let newPrice = prevSpecs;
          newPrice[index].price = requiredErr;
          return newPrice;
        });
      }
      return "";
    });
    return hasError;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.target as HTMLFormElement);
    let ValidData = validateForm(fd);
    if(fd.get('order') === ''){
      fd.delete('order');
    }
    const validSpecs = validateSpecification(product.specifications);
    const validKeys =
      product.category === 1 ? validateKeypoints(product.keyPoints) : false;
    const validPrice =
      product.category !== 1 ? validateAllPrices(product.prices) : false;

    fd.append("specifications", JSON.stringify(product.specifications));
    fd.append("prices", JSON.stringify(product.prices));
    fd.append("status", JSON.stringify(disabled));  
    fd.append("availability", JSON.stringify(available));

    const method = id ? "PUT" : "POST";
    const url = id
      ? `/api/product/${id}` + (isDocumentDeleted ? "?dd=1" : "")
      : "/api/product";

    if (!ValidData && !validKeys && !validSpecs && !validPrice) {
      setLoading(true);
      try {
        const response = await axiosInstance({
          method: method,
          url: url,
          data: fd,
          withCredentials: true,
        });

        const data = response.data;
        if (data.status === 1) {
          navigate("/admin/products", {
            state: {
              showToast: id
                ? `${product.name} edited successfully`
                : `${product.name} created successfully`,
            },
          });
        }
      } catch (e: any) {
        console.error(e.message);
        toast.error("Something went wrong");
        setLoading(false);
      }
    }
  };

  const handleDeleteDocument = () => {
    // if(docRef.current){
    //     docRef.current.value = "";
    // }
    setIsDocumentDeleted(true);
    setProduct((prev) => ({
      ...prev,
      document: null,
    }));
  };

  const handleImageDelete = () => {
    if (imageRef.current) {
      imageRef.current.value = "";
    }
    setImagePreview(null);
    setProduct((prev) => ({
      ...prev,
      image: null,
    }));
  };


  const handleCancel = ():void =>  {
    if(window.confirm('Your form data will be lost. Do you want to cancel?')){
      navigate('/admin/products')
    }
  }

  if (product) {
    return (
      <>
        <div className="px-40 ">
          <h1 className="font-bold text-2xl py-5 ">
            {id ? "Edit  Product" : "Add a New Product"}
          </h1>
          <form
            className="grid grid-cols-2 gap-7 bg-primary p-10 bg-opacity-[0.15] mb-10"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col relative">
              <InputField
                type="text"
                placeholder="Name *"
                fieldName="name"
                onBlur={(e) => handleInputBlur(e)}
                value={product.name}
                onChange={handleChange}
                className={`${
                  errors.name ? " border-[1px] border-red-500" : ""
                }`}
              />
              {errors.name && <ErrorSpan error={errors.name} />}
            </div>
            <div className="flex flex-col relative">
              <InputField
                type="text"
                placeholder="Catalog Number *"
                fieldName="catalogNo"
                onBlur={(e) => handleInputBlur(e)}
                value={product.catalogNo}
                onChange={handleChange}
                className={`${
                  errors.catalogNo ? " border-[1px] border-red-500" : ""
                }`}
              />
              {errors.catalogNo && <ErrorSpan error={errors.catalogNo} />}
            </div>
            <div className="flex flex-col relative">
              <InputField
                type="text"
                placeholder="Cas Number"
                fieldName="casNo"
                onBlur={(e) => handleInputBlur(e)}
                value={product.casNo}
                onChange={handleChange}
                className={`${
                  errors.casNo ? " border-[1px] border-red-500" : ""
                }`}
              />
              {errors.casNo && <ErrorSpan error={errors.casNo} />}
            </div>
            <div className="flex flex-col relative">
              <select
                className={`outline-none px-1 h-12 ${
                  errors.category ? " border-[1px] border-red-500" : ""
                }`}
                name="category"
                value={product.category?.toString()}
                onChange={handleChange}
                onBlur={(e) => handleInputBlur(e)}
              >
                <option value={0}>Category *</option>
                <option value={1}>Peptide Synthesizers</option>
                <option value={2}>Amino Acids</option>
                <option value={3}>Reagent and linkers</option>
                <option value={4}>Resins</option>
              </select>
              {errors.category && <ErrorSpan error={errors.category} />}
            </div>
            <div className="flex flex-col relative">
              <InputField
                type="string"
                placeholder="Order"
                fieldName="order"
                onBlur={(e) => handleInputBlur(e)}
                value={product.order}
                onChange={handleChange}
                className={`${
                  errors.order ? " border-[1px] border-red-500" : ""
                }`}
              />
              {errors.order && <ErrorSpan error={errors.order} />}
            </div>

            {product.category === 1 && (
              <div className="flex flex-col col-span-2 relative">
                <textarea
                  className={`outline-none px-2 py-1 col-span-2 placeholder:text-grey ${
                    errors.shortDescription
                      ? " border-[1px] border-red-500"
                      : ""
                  }`}
                  onBlur={(e) => handleInputBlur(e)}
                  placeholder="Short Description"
                  name="shortDescription"
                  value={product.shortDescription}
                  rows={5}
                  onChange={handleChange}
                />
                {errors.shortDescription && (
                  <ErrorSpan error={errors.shortDescription} />
                )}
              </div>
            )}

            {product.category === 1 ? (
              <div className="col-span-2 flex flex-col gap-7">
                <span className="font-medium">Key Points:</span>

                {product.keyPoints.map((keyPoint, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center gap-7"
                  >
                    <div className="flex flex-col relative">
                      <div className="grid grid-cols-12 items-center">
                        <textarea
                          rows={3}
                          className={`w-full outline-none ${
                            keyPointsError[index]
                              ? " border-[1px] border-red-500"
                              : ""
                          } px-2 py-1 placeholder:text-grey col-span-11`}
                          value={keyPoint}
                          onChange={(e) =>
                            handleKeyPointsChange(index, e.target.value)
                          }
                          onBlur={(e) => handleKeyBlur(index, e.target.value)}
                          name="keyPoints[]"
                          placeholder="Add Point *"
                        />
                        <button
                          className="bg-primary ms-1 text-white px-3 py-2 h-12"
                          title="Remove"
                          type="button"
                          onClick={() => removeKeyPoint("keyPoint", index)}
                        >
                          Remove
                        </button>
                      </div>
                      {keyPointsError[index] && (
                        <ErrorSpan error={keyPointsError[index]} />
                      )}
                    </div>
                  </div>
                ))}

                <button
                  className="bg-primary text-white px-3 py-2"
                  title="Add Key Point"
                  type="button"
                  onClick={() => {
                    addKeyPoint("keyPoint");
                  }}
                >
                  Add Keypoint
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="flex gap-7 col-span-2 flex-col">
              <label className="font-medium">Specifications:</label>
              {product.specifications.map((specification, index) => {
                return (
                  <div key={index} className="grid grid-cols-7 gap-3">
                    <div className="flex flex-col relative col-span-3">
                      <InputField
                        type="text"
                        value={specification.key}
                        onChange={(e) => {
                          handleSpecificationChange(e, index, "key");
                        }}
                        fieldName="specificationType"
                        onBlur={(e) =>
                          handleSpecificationBlur(e, index, "specificationType")
                        }
                        className={`${
                          specificationError[index]?.specificationType
                            ? "border-[1px] border-red-500"
                            : ""
                        }`}
                        placeholder="Specification *"
                      />
                      {specificationError[index] && (
                        <ErrorSpan
                          error={specificationError[index]?.specificationType}
                        />
                      )}
                    </div>
                    <div className="flex flex-col relative col-span-3">
                      <InputField
                        type="text"
                        value={specification.value}
                        fieldName="specificationDetail"
                        placeholder="Specification Type *"
                        onBlur={(e) =>
                          handleSpecificationBlur(
                            e,
                            index,
                            "specificationDetail"
                          )
                        }
                        className={`${
                          specificationError[index]?.specificationDetail
                            ? "border-[1px] border-red-500"
                            : ""
                        }`}
                        onChange={(e) => {
                          handleSpecificationChange(e, index, "value");
                        }}
                      />
                      {specificationError[index] && (
                        <ErrorSpan
                          error={specificationError[index]?.specificationDetail}
                        />
                      )}
                    </div>
                    <CustomButton
                      title="Remove"
                      onClick={() => removeKeyPoint("specification", index)}
                    />
                  </div>
                );
              })}
              <CustomButton
                title="Add Specification"
                onClick={() => addKeyPoint("specification")}
              />
            </div>

            {product.category !== 1 && (
              <div className="flex gap-7 col-span-2 flex-col">
                <label className="font-medium">Prices:</label>
                {product.prices.map((price, index) => {
                  return (
                    <div key={index} className="grid grid-cols-7 gap-3">
                      <div className="flex flex-col relative col-span-3">
                        <InputField
                          type="text"
                          value={price.quantity}
                          onChange={(e) => {
                            handlePriceChange(e, index, "quantity");
                          }}
                          fieldName="quantity"
                          onBlur={(e) => handlePriceBlur(e, index, "quantity")}
                          className={`${
                            pricesError[index]?.quantity
                              ? "border-[1px] border-red-500"
                              : ""
                          }`}
                          placeholder="Quantity *"
                        />
                        {pricesError[index] && (
                          <ErrorSpan error={pricesError[index]?.quantity} />
                        )}
                      </div>
                      <div className="flex flex-col relative col-span-3">
                        <InputField
                          type="text"
                          value={price.price}
                          fieldName="price"
                          placeholder="price *"
                          onBlur={(e) => handlePriceBlur(e, index, "price")}
                          className={`${
                            pricesError[index]?.price
                              ? "border-[1px] border-red-500"
                              : ""
                          }`}
                          onChange={(e) => {
                            handlePriceChange(e, index, "price");
                          }}
                        />
                        {pricesError[index] && (
                          <ErrorSpan error={pricesError[index]?.price} />
                        )}
                      </div>
                      <CustomButton
                        title="Remove"
                        onClick={() => removeKeyPoint("price", index)}
                      />
                    </div>
                  );
                })}
                <CustomButton
                  title="Add Price"
                  onClick={() => addKeyPoint("price")}
                />
              </div>
            )}

            {!product.image && (
              <div className="col-span-2 grid grid-cols-subgrid items-center px-2">
                <label htmlFor="productImg" className="font-medium">
                  Choose Product Image *
                </label>
                <div className="flex flex-col relative">
                  <InputField
                    type="file"
                    placeholder=""
                    ref={imageRef}
                    accept="image/*"
                    fieldName="productImg"
                    onBlur={(e) => handleInputBlur(e)}
                    onChange={handleImageChange}
                  />
                  {errors.productImg && <ErrorSpan error={errors.productImg} />}
                </div>
              </div>
            )}
            {imagePreview && (
              <div className="col-span-2 grid grid-cols-subgrid  items-center px-2">
                <label htmlFor="productImg" className="font-medium">
                  Uploaded Product Image:
                </label>
                <div className="flex flex-col gap-2">
                  <img
                    src={imagePreview}
                    alt="Uploaded Product"
                    className="w-28 h-28"
                  />
                  <span
                    className="hover:text-red-500 underline cursor-pointer"
                    onClick={handleImageDelete}
                  >
                    Remove Image
                  </span>
                </div>
              </div>
            )}

            {!product.document && (
              <div className="col-span-2 grid grid-cols-subgrid items-center px-2">
                <label htmlFor="productImg" className="font-medium">
                  Choose Product Document
                </label>
                <div className="flex flex-col relative">
                  <InputField
                    type="file"
                    accept="application/pdf"
                    placeholder=""
                    ref={docRef}
                    fieldName="productDocument"
                    onBlur={(e) => handleInputBlur(e)}
                    onChange={handleDocumentChange}
                  />
                  {errors.productDocument && (
                    <ErrorSpan error={errors.productDocument} />
                  )}
                </div>
              </div>
            )}

            {id && product.document && !isDocumentDeleted && (
              <div className="col-span-2 grid grid-cols-subgrid items-center px-2">
                <label className=" font-medium">Uploaded Document</label>
                <div className="flex gap-4">
                  <a
                    href={product?.document?.toString()}
                    className="underline hover:text-primary"
                  >
                    Show Document
                  </a>
                  <span
                    className="hover:text-red-500 underline cursor-pointer"
                    onClick={handleDeleteDocument}
                  >
                    Delete Document
                  </span>
                </div>
              </div>
            )}

            <div className="flex gap-7">
              <div className="flex gap-6 justify-start ms-2 items-center">
                <label className="font-medium">Active</label>
                <Switch
                  name="disabled"
                  checked={disabled === 1}
                  onChange={()=>{handleDisableProduct(0)}}
                  onColor="#69A8AA"
                  onHandleColor="#056E72"
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={40}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
              <div className="flex gap-6">
                <label className="font-medium">Available</label>
                <Switch
                  name="available"
                  checked={available === 1}
                  onChange={()=>{handleDisableProduct(1)}}
                  onColor="#69A8AA"
                  onHandleColor="#056E72"
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={40}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>

            <CustomButton
              className="col-start-1"
              type="submit"
              title="Submit"
              loading={loading}
            />
            <CustomButton title="Cancel" onClick={handleCancel}/>
          </form>
        </div>
        <ToastContainer />
      </>
    );
  } else {
    return <h1>No product found</h1>;
  }
};
